import { gsap } from "gsap";
import { hero } from "../js/anims/hero.js";
import { lenis } from "../js/anims/lenis.js";
import { methods } from "../js/anims/methods.js";
import { speech } from "../js/anims/speech.js";
import { stickers } from "../js/anims/stickers.js";
import Lenis from 'lenis';
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {
        document.querySelector('.js-hero') ? hero() : false;
        document.querySelector('.js-speech') ? speech() : false;
        document.querySelector('.js-stickers') ? stickers() : false;
        document.querySelector('.js-methods') ? methods() : false;
    };
    
    lenis();

}, false)
