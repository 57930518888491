document.addEventListener('DOMContentLoaded', () => {
    const el = document.getElementById("video"); 
    
    const init = () => {                
        const pause_it = () => {
            document.querySelector('.o-video').classList.add('is-paused');
        }
    
        const observer = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting) {                
                el.muted = true;
                el.play().catch(error => {
                    console.log('Autoplay blocked:', error);
                });
            } else {
                console.log('pause');
                el.pause(); 
            }
        });

        observer.observe(el)
    }
    
    el ? init() : false;

}, false)
