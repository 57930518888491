import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function hero() {
    let mediaQuery = '(min-width: 641px)',
        mediaQueryList = window.matchMedia(mediaQuery);
   
    const desktop = () => {
        gsap.to(".c-hero", {
            yPercent: 20,
            ease: "none",
            scrollTrigger: {
                trigger: ".c-hero",
                scrub: 0,
                start: 'top top',
            }, 
        });
    }

    const mobile = () => {
        gsap.to(".c-hero", {
            yPercent: 20,
            ease: "none",
            scrollTrigger: {
                trigger: ".c-hero",
                scrub: 0,
                start: 'center top',
            }, 
        });
    }


    mediaQueryList.addEventListener('change', event => {
        if(event.matches) {
            desktop();
        } else {
            mobile();
        }   
    })

    if (mediaQueryList.matches) {
        desktop();
    } else {
        mobile();
    }
}

