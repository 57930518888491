document.addEventListener('DOMContentLoaded', () => {
    const methods = document.querySelectorAll(".js-method");
    const photos = document.querySelectorAll('.js-methods-photo');

    const init = () => {
        let mediaQuery = '(max-width: 768px)',
            mediaQueryList = window.matchMedia(mediaQuery);
        
        const start = () => {
            const options = {
                root: null,
                threshold: 0.5 // 50% elementu musi być widoczne, aby aktywować
            }
    
            let activeIndex = -1; // Aby śledzić, który element jest aktywny
    
            const observer = new IntersectionObserver((entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const newIndex = Array.from(methods).indexOf(entry.target);
    
                        // Tylko zmieniaj, jeśli nowy indeks jest inny
                        if (newIndex !== activeIndex) {
                            if (activeIndex >= 0) {
                                photos[activeIndex].classList.remove('is-active');
                            }
                            photos[newIndex].classList.add('is-active');
                            activeIndex = newIndex;
                        }
                    }
                });
            }, options);
    
            methods.forEach(card => observer.observe(card));
        }
        
        mediaQueryList.addEventListener('change', event => {
            if (event.matches) {
                start();
            } else {
                methods.forEach(card => observer.unobserve(card));
            }
        });
    
        if (mediaQueryList.matches) {
            start();
        }
    }

    if (methods.length > 0) {
        init();
    }
}, false);