document.addEventListener('DOMContentLoaded', () => {
    
    if (!document.querySelector('.js-flyingbar') || !document.querySelector('.js-hero')) return;
    
    const el = document.querySelector('.js-flyingbar'),
          hero = document.querySelector('.js-hero');
    let narrow = false;

    const action = () => {
        let scroll_pos = window.pageYOffset || window.scrollY;
        scroll_pos > hero.clientHeight ? document.documentElement.classList.add('show-flyingbar') : document.documentElement.classList.remove('show-flyingbar');
    }

    action();
    el ? window.addEventListener('scroll', action) : false;

}, false);
