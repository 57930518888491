import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Funkcja główna zawierająca animacje i logikę resetowania
export function methods() {
    let mediaQuery = '(min-width: 769px)',
        mediaQueryList = window.matchMedia(mediaQuery),
        animations = []; // Tablica do przechowywania referencji do animacji

    const body = document.querySelector("body");

    // Funkcja uruchamiająca animacje
    const start = () => {
        // Tworzenie animacji i ich zapisanie do tablicy
        animations.push(
            gsap.to('.js-methods .o-grid', {
                scrollTrigger: {
                    pin: '.js-methods .o-grid',
                    end: '+=8500s',
                    pinSpacing: true,
                },
            }),

            gsap.to('#method_01', {
                scrollTrigger: {
                    trigger: '#method_01',
                    toggleActions: 'play reverse play complete',
                    start: '0s',
                    end: '+=1000s',
                    onEnter: () => {
                        body.classList.remove('bg-violet'); 
                    },
                    onEnterBack: () => {
                        body.classList.remove('bg-violet'); 
                    },
                },
                opacity: 1,
            }, '-=1000s'),

            gsap.fromTo(
                '#method_02',
                { 
                    opacity: 0, 
                    y: 50 // Początkowa pozycja na osi Y
                },
                {
                    opacity: 1,
                    y: 0, // Docelowa pozycja na osi Y
                    scrollTrigger: {
                        trigger: '#method_02',
                        toggleActions: 'play reverse play reverse',
                        start: '+=1000s',
                        end: '+=1000s',
                        onEnter: () => {
                            body.classList.remove('bg-violet'); 
                        },
                        onEnterBack: () => {
                            body.classList.remove('bg-violet'); 
                        }
                    },
                }
            ),
            
            gsap.fromTo(
                '#method_03',
                { 
                    opacity: 0, 
                    y: 50
                },
                {
                    opacity: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: '#method_03',
                        toggleActions: 'play reverse play reverse',
                        start: '+=2000s',
                        end: '+=1000s',
                        onEnter: () => {
                            body.classList.remove('bg-violet'); 
                        },
                        onEnterBack: () => {
                            body.classList.remove('bg-violet'); 
                        }
                    },
                }
            ),
            
            gsap.fromTo(
                '#method_04',
                { 
                    opacity: 0, 
                    y: 50
                },
                {
                    opacity: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: '#method_04',
                        toggleActions: 'play reverse play reverse',
                        start: '+=3000s',
                        end: '+=1000s',
                        onEnter: () => {
                            body.classList.remove('bg-violet'); 
                        },
                        onEnterBack: () => {
                            body.classList.remove('bg-violet'); 
                        }
                    },
                }
            ),
            
            gsap.fromTo(
                '#method_05',
                { 
                    opacity: 0, 
                    y: 50
                },
                {
                    opacity: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: '#method_05',
                        toggleActions: 'play reverse play reverse',
                        start: '+=4000s',
                        end: '+=1000s',
                        onEnter: () => {
                            body.classList.add('bg-violet'); 
                        }
                    },
                }
            ),
            
            gsap.fromTo(
                '#method_06',
                { 
                    opacity: 0, 
                    y: 50
                },
                {
                    opacity: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: '#method_06',
                        toggleActions: 'play reverse play reverse',
                        start: '+=5000s',
                        end: '+=1000s',
                        onEnter: () => {
                            body.classList.add('bg-violet'); 
                        }
                    },
                }
            ),
            
            gsap.fromTo(
                '#method_07',
                { 
                    opacity: 0, 
                    y: 50
                },
                {
                    opacity: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: '#method_07',
                        toggleActions: 'play reverse play reverse',
                        start: '+=6000s',
                        end: '+=1000s',
                        onEnter: () => {
                            body.classList.add('bg-violet'); 
                        }
                    },
                }
            ),
           

            gsap.fromTo(
                '#method_08',
                { 
                    opacity: 0, 
                    y: 50
                },
                {
                    opacity: 1,
                    y: 0,
                    scrollTrigger: {
                        trigger: '#method_08',
                        toggleActions: 'play reverse play reverse',
                        start: '+=7000s',
                        end: '+=7000s',
                        onEnter: () => {
                            body.classList.add('bg-violet'); 
                        }
                    },
                }
            ),
        );
    };

    // Funkcja zatrzymująca i usuwająca wszystkie animacje oraz style
    const resetAnimations = () => {
        // Zatrzymaj i usuń wszystkie animacje
        animations.forEach(animation => animation.kill());
        animations = []; // Wyczyść tablicę animacji
        
        // Wyczyść wszystkie ScrollTriggers
        ScrollTrigger.getAll().forEach(trigger => trigger.kill());

        // Usunięcie stylów inline na wszystkich elementach
        gsap.set(['.js-methods .o-grid', '#method_01', '#method_02', '#method_03', '#method_04', '#method_05', '#method_06', '#method_07', '#method_08'], { clearProps: 'all' });
    };

    // Przykład: resetowanie animacji po kliknięciu przycisku
    /*
document.querySelector('#resetButton').addEventListener('click', () => {
        resetAnimations(); // Zatrzymaj aktualne animacje i wyczyść style
        
        setTimeout(function() {
            start(); // Uruchom ponownie animacje            
        }, 3000)

    });
*/

    // Obsługa zmiany szerokości okna (media query)
    mediaQueryList.addEventListener('change', event => {
        if (event.matches) {
            start();
        } else {
            resetAnimations(); // Zatrzymaj animacje przy zmianie media query
        }
    });

    // Inicjalne uruchomienie animacji przy załadowaniu
    if (mediaQueryList.matches) {
        start();
    }
}