import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Lenis from 'lenis'

export function lenis() {
    gsap.registerPlugin(ScrollTrigger);
    
    const lenis = new Lenis()

    lenis.on('scroll', (e) => {
      //console.log(e)
    })
    
    lenis.on('scroll', ScrollTrigger.update)
    
    gsap.ticker.add((time)=>{
      lenis.raf(time * 1000)
    })
    
    gsap.ticker.lagSmoothing(0)
}
