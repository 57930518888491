document.addEventListener('DOMContentLoaded', () => {

    if (!document.querySelector('.js-widget')) return;
    
    const slogans = document.querySelectorAll('.js-widget li');
    let currentIndex = 0;
    const intervalTime = 500; // Czas między animacjami
    const pauseTime = 1500; // Czas zatrzymania na ekranie (1 sekunda)

    const showSlogan = () => {
        slogans.forEach((slogan) => {
            slogan.classList.remove('from-bottom', 'to-top');
            slogan.style.opacity = '0';
            slogan.style.visibility = 'hidden';
        });

        const currentSlogan = slogans[currentIndex];
        currentSlogan.style.opacity = '1';
        currentSlogan.style.visibility = 'visible';
        currentSlogan.classList.add('from-bottom'); // Dodajemy klasę wjeżdżania od dołu

        setTimeout(() => {
            currentSlogan.classList.remove('from-bottom');
            currentSlogan.classList.add('to-top'); // Po 1 sekundzie, przesuwamy go do góry
        }, pauseTime);

        currentIndex = (currentIndex + 1) % slogans.length; // Przejdź do następnego
    };

    showSlogan(); // Pokaż pierwszy slogan

    setInterval(showSlogan, intervalTime + pauseTime); // Ustaw interwał z uwzględnieniem czasu pauzy
});